import { en } from '../../../i18n';
import Button from '../../components/Button';
import { Title } from '../styled';
import {
  SubTitleA,
  SubTitleB,
  SubTitleC,
  ListContainer,
  ListItem,
  ListDetail,
  ListTitle,
  PreButton,
  ContentContainer,
  ButtonContainer,
  ListSubTitle,
  DisclaimerContainer,
} from './styled';
import parse from 'html-react-parser';
import { useMediaQuery, useTheme } from '@mui/material';
import { ReactComponent as Practice } from '../../../images/icons/dashboard/parctice.svg';
import { ReactComponent as Review } from '../../../images/icons/dashboard/review.svg';
import { ReactComponent as NurseGPTIcon } from '../../../images/nurseGPT.svg';
import { EUserRole } from '../../types';

const { onBoarding } = en;

const { ready } = onBoarding;

//const icons = [Practice, Review, NurseGPTIcon];
const icons = [NurseGPTIcon, Practice, Review];
const studentIcons = [NurseGPTIcon, Practice, Review];

interface IReady {
  onReady: (e: any) => void;
  isError?: boolean;
  role: EUserRole;
}

const Ready = ({ onReady, role }: IReady) => {
  const isUndergrad = role === EUserRole.UNDERGRAD;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const isMobileDetails = useMediaQuery(theme.breakpoints.down('lg'));

  const renderList = () => {
    const dataSource = isUndergrad ? ready.studentlist : ready.list;
    const iconsSource = isUndergrad ? studentIcons : icons;
    return dataSource.map((listItem, index) => {
      const Icon = iconsSource[index];
      return (
        <ListItem key={index}>
          <ListTitle>
            <Icon />
            <div>{listItem.title}</div>
          </ListTitle>
          <ListSubTitle>{listItem.subtitle}</ListSubTitle>
          <ListDetail>{parse(isMobileDetails ? listItem.detailsMobile : listItem.details)}</ListDetail>
        </ListItem>
      );
    });
  };

  const markAsDone = () => {
    onReady(true);
  };

  return (
    <>
      <Title isLargeTitle>{ready.title}</Title>
      {/* <SubTitleA>{ready.subTitleA}</SubTitleA> */}
      <ContentContainer>
        <SubTitleB>{isUndergrad ? ready.SubTitleBStudent : ready.SubTitleB}</SubTitleB>
        <SubTitleB>{ready.subTitleA}</SubTitleB>
        <ListContainer>{renderList()}</ListContainer>
        <PreButton>{parse(ready.preButton)}</PreButton>
        <ButtonContainer>
          <Button onClick={markAsDone} fullWidth={isMobile} size="large" variant="primary">
            {ready.buttonText}
          </Button>
        </ButtonContainer>
        <DisclaimerContainer>{ready.disclaimer}</DisclaimerContainer>
      </ContentContainer>
    </>
  );
};

export default Ready;
