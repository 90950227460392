import { Input, useMediaQuery, useTheme } from '@mui/material';
import { sampleSize, sample } from 'lodash';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CloseIcon from '@mui/icons-material/Close';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { BounceLoader } from 'react-spinners';
import { marked } from 'marked';
import parse from 'html-react-parser';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Button from '../components/Button';
import CustomTooltip from '../components/Tooltip';
import {
  PageContainer,
  ChatContainer,
  MessagesContainer,
  Message,
  MessageAvatar,
  MessageText,
  MessagesWrapper,
  MessageIcon,
  UnstartedChat,
  UnstartedChatTitle,
  UnstartedChatExamplesContainer,
  UnstartedChatExamples,
  UnstartedChatExample,
  UnstartedChatIcon,
  UnstartedChatHeader,
  UnstartedChatExampleTitle,
  SubmitButtonContainer,
  DisclaimerText,
  MessageLoader,
  SessionsContainer,
  SessionsHeader,
  BetaTag,
  PlusButton,
  NoSessions,
  AboutButton,
  ModalOverlay,
  ModalContainer,
  ModalTitle,
  AboutModalBody,
  SessionsList,
  SessionsSection,
  SessionsSectionTitle,
  MobileHeader,
  DeleteModalBody,
  DeleteModalButtonsContainer,
  RoleContainer,
  MobileRoleContainer,
  SelectedRole,
  RoleDropdown,
  RoleDropdownItem,
  InfiniteLoader,
  UploadedFileName,
  UploadMessage,
  CopyContainer,
  UploadError,
  ButtonTipsPopupContainer,
} from './styled';
import { en } from '../../i18n';
import { useDidMount, useNurseGPT } from '../hooks';
import { ISession, IMessage, ENurseGPTRole, EUserRole } from '../types';
import { colors } from '../../themes';
import { ReactComponent as NurseGPTIcon } from '../../images/nurseGPT.svg';
import { ReactComponent as PlusIcon } from '../../images/plusWhite.svg';
import { ReactComponent as InfoIcon } from '../../images/questionMark.svg';
import { ReactComponent as FolderIcon } from '../../images/folder.svg';
import { ReactComponent as DownArrowIcon } from '../../images/downArrow.svg';
import { ReactComponent as UploadIcon } from '../../images/nursegpt-upload.svg';
import { ReactComponent as FileIcon } from '../../images/file.svg';
import { ReactComponent as CopyIcon } from '../../images/copy.svg';
import { ReactComponent as ErrorIcon } from '../../images/icons/error.svg';
import Spinner from '../components/Spinner';
import Session from './components/Session';
import Tips from './components/tips';
import { useEffect, useState } from 'react';

const {
  title,
  beta,
  initialPlaceholder,
  ongoingPlaceholder,
  you,
  unstartedChatTitle,
  disclaimer,
  noSessionsTitle,
  noSessionsBody,
  about,
  firstAboutParagraph,
  secondAboutParagraph,
  close,
  prompts,
  initialMobilePlaceholder,
  ongoingMobilePlaceholder,
  deleteSessionTitle,
  willDelete,
  cantUndone,
  deleteButton,
  cancel,
  rolePrefix,
  mobileRolePrefix,
  trouble,
  somethingWrong,
  refreshButton,
  uploadFailed,
  retryUpload,
  pdf,
  copy,
  copied,
  closeTips,
} = en.nurseGPT;

const nursePrompts = sampleSize(Object.values(prompts.nurse), 4).map(category => sample(category));
const studentPrompts = sampleSize(Object.values(prompts.student), 4).map(category => sample(category));

const NurseGPT = () => {
  const [firstButtonClick, setFirstButtonClick] = useState(false);
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));

  const {
    state: {
      chat,
      prompt,
      messagesWrapperRef,
      typedResponseRef,
      userFirstLetter,
      loadingResponse,
      isUnstarted,
      noSessions,
      tipsPopup,
      groupedSessions,
      mobileDrawerOpen,
      loadingSessions,
      inputRef,
      typedResponse,
      clickedSessionMenu,
      showDeleteModal,
      sessionToDelete,
      sessionToRename,
      sessionsTitlesRef,
      sessionsListRef,
      roleValue,
      roleDropdownOpen,
      currentSession,
      svgLoader,
      showErrorModal,
      storedRole,
      isRoleLoading,
      currentFile,
      fileInputKey,
      copyToolTipText,
      fileUpload,
      errors,
    },
    control: {
      handlePromptChange,
      onKeyDown,
      handlePromptSubmit,
      handleStartNewChat,
      toggleTipsPopup,
      setCurrentSession,
      toggleMobileDrawer,
      handleExampleClick,
      getPlaceholder,
      handleSessionMenuClick,
      handleSessionMenuClickaway,
      handleSessionDelete,
      toggleDeleteModal,
      handleDeleteClick,
      handleRenameClick,
      handleRenameBlur,
      onRenameKeyDown,
      handleRoleChange,
      handleMobileRoleChange,
      toggleRoleDropdown,
      setRoleDropdownOpen,
      displaySessionMenuUp,
      refresh,
      handleMessagesScroll,
      onFileChange,
      cancelFileUpload,
      setCopyTooltipText,
    },
  } = useNurseGPT();

  const onClickAbout = () => {
    setFirstButtonClick(true);
    toggleTipsPopup();
  };

  const renderMessage = (message: IMessage, index: number) => (
    <Message>
      <MessageAvatar>
        {message.source === 'user' ? (
          <>
            <MessageIcon backgroundColor={colors.purple30}>{userFirstLetter}</MessageIcon>
            {you}
          </>
        ) : (
          <>
            <MessageIcon backgroundColor={colors.white}>
              <NurseGPTIcon />
            </MessageIcon>
            {title}
          </>
        )}
      </MessageAvatar>
      {message.content.startsWith('Uploaded File:') ? (
        <UploadMessage>
          <div>
            <FileIcon />
          </div>
          <div>
            <div>{message.content.slice('Uploaded File: '.length)}</div>
            <div>{message.content.split('.').pop()?.toUpperCase()}</div>
          </div>
        </UploadMessage>
      ) : (
        <>
          <MessageText>
            <div>{parse(marked.parse(message.content.replace(/(\【.*?\】)/g, '')) as string)}</div>
          </MessageText>
          {message.source === 'assistant' && (
            <CopyContainer isLastMessage={index === chat.length - 1}>
              <CopyToClipboard
                text={message.content.replace(/(\【.*?\】)/g, '')}
                onCopy={() => setCopyTooltipText(copied)}
              >
                <CustomTooltip
                  centerText
                  arrow={false}
                  title={copyToolTipText}
                  width="fit-content"
                  padding="8px 16px"
                  enterTouchDelay={0}
                  onClose={() => setTimeout(() => setCopyTooltipText(copy), 200)}
                >
                  <span>
                    <CopyIcon />
                  </span>
                </CustomTooltip>
              </CopyToClipboard>
            </CopyContainer>
          )}
        </>
      )}
    </Message>
  );

  const renderUnstartedChat = () => {
    return (
      <UnstartedChat>
        <UnstartedChatHeader>
          <UnstartedChatIcon>
            <NurseGPTIcon />
          </UnstartedChatIcon>
          <UnstartedChatTitle>{unstartedChatTitle}</UnstartedChatTitle>
        </UnstartedChatHeader>
        <UnstartedChatExamplesContainer>
          <UnstartedChatExamples>
            {isRoleLoading ? (
              <Spinner loading={isRoleLoading} />
            ) : (
              (storedRole === EUserRole.UNDERGRAD ? studentPrompts : nursePrompts).map(prompt => (
                <UnstartedChatExample onClick={() => handleExampleClick(prompt || '')}>
                  <UnstartedChatExampleTitle>{prompt}</UnstartedChatExampleTitle>
                </UnstartedChatExample>
              ))
            )}
          </UnstartedChatExamples>
        </UnstartedChatExamplesContainer>
      </UnstartedChat>
    );
  };

  const renderTypedResponse = () => {
    if (!loadingResponse || !currentSession?.receivingResponse) return null;
    return (
      <Message>
        <MessageAvatar>
          <MessageIcon backgroundColor={colors.white}>
            <NurseGPTIcon />
          </MessageIcon>
          {title}
        </MessageAvatar>
        <MessageText>
          <div ref={typedResponseRef}>
            {parse(marked.parse(`${typedResponse.replace(/(\【.*?\】)/g, '')} ${svgLoader}`) as string)}
          </div>
        </MessageText>
      </Message>
    );
  };

  const renderChatBody = () => {
    return !isUnstarted ? (
      <MessagesWrapper ref={messagesWrapperRef} onScroll={handleMessagesScroll}>
        <MessagesContainer>
          {currentSession?.loadingSession && chat.length === 0 ? (
            <Spinner loading={currentSession.loadingSession} />
          ) : (
            <>
              {currentSession?.loadingSession && (
                <InfiniteLoader>
                  <Spinner loading={currentSession.loadingSession} inline size="20px" />
                </InfiniteLoader>
              )}
              {chat
                .filter((message: IMessage) => message.content)
                .map((message: IMessage, index: number) => renderMessage(message, index))}
              {renderTypedResponse()}
            </>
          )}
        </MessagesContainer>
      </MessagesWrapper>
    ) : (
      renderUnstartedChat()
    );
  };

  const renderPromptInput = () => {
    return (
      <div>
        {/* <RoleContainer>
          <div>{rolePrefix}</div>
          <RadioGroup value={roleValue} onChange={handleRoleChange}>
            <FormControlLabel
              value={ENurseGPTRole.NURSING_STUDENT}
              control={<Radio />}
              label={ENurseGPTRole.NURSING_STUDENT}
            />
            <FormControlLabel value={ENurseGPTRole.NCLEX_TAKER} control={<Radio />} label={ENurseGPTRole.NCLEX_TAKER} />
            <FormControlLabel
              value={ENurseGPTRole.PRACTICING_NURSE}
              control={<Radio />}
              label={ENurseGPTRole.PRACTICING_NURSE}
            />
          </RadioGroup>
        </RoleContainer>
        <ClickAwayListener onClickAway={() => setRoleDropdownOpen(false)}>
          <MobileRoleContainer>
            <SelectedRole onClick={toggleRoleDropdown}>
              {mobileRolePrefix} {roleValue}
              <DownArrowIcon />
            </SelectedRole>
            <RoleDropdown isOpen={roleDropdownOpen}>
              <RoleDropdownItem
                onClick={() => handleMobileRoleChange(ENurseGPTRole.NURSING_STUDENT)}
                selected={roleValue === ENurseGPTRole.NURSING_STUDENT}
              >
                {mobileRolePrefix} {ENurseGPTRole.NURSING_STUDENT}
              </RoleDropdownItem>
              <RoleDropdownItem
                onClick={() => handleMobileRoleChange(ENurseGPTRole.NCLEX_TAKER)}
                selected={roleValue === ENurseGPTRole.NCLEX_TAKER}
              >
                {mobileRolePrefix} {ENurseGPTRole.NCLEX_TAKER}
              </RoleDropdownItem>
              <RoleDropdownItem
                onClick={() => handleMobileRoleChange(ENurseGPTRole.PRACTICING_NURSE)}
                selected={roleValue === ENurseGPTRole.PRACTICING_NURSE}
              >
                {mobileRolePrefix} {ENurseGPTRole.PRACTICING_NURSE}
              </RoleDropdownItem>
            </RoleDropdown>
          </MobileRoleContainer>
        </ClickAwayListener> */}
        <Input
          maxRows={20}
          placeholder={getPlaceholder(
            initialPlaceholder,
            ongoingPlaceholder,
            initialMobilePlaceholder,
            ongoingMobilePlaceholder,
          )}
          onChange={handlePromptChange}
          value={prompt}
          disableUnderline
          fullWidth
          multiline
          onKeyDown={onKeyDown}
          inputRef={inputRef}
        />
        <SubmitButtonContainer>
          <DisclaimerText>{disclaimer}</DisclaimerText>
          <InputAdornment position="end">
            <IconButton onClick={handlePromptSubmit} edge="end" disabled={(!prompt && !currentFile) || loadingResponse || fileUpload?.failed}>
              <ArrowUpwardIcon />
            </IconButton>
          </InputAdornment>
          <InputAdornment position="start">
            <label htmlFor="nursegpt-file-upload">
              <IconButton component="span" edge="start" disabled={loadingResponse}>
                <UploadIcon />
              </IconButton>
            </label>
            <input
              key={fileInputKey}
              onChange={onFileChange}
              accept=".pdf,.txt,.docx"
              id="nursegpt-file-upload"
              type="file"
              disabled={loadingResponse}
            />
          </InputAdornment>
          {currentFile && !fileUpload?.failed && (
            <UploadedFileName>
              <FileIcon />
              {currentFile.name.length > 20 ? `${currentFile.name.slice(0, 21)}...` : currentFile.name}
              {loadingResponse && <Spinner loading={loadingResponse} inline size="20px" />}
              <button onClick={cancelFileUpload}>X</button>
            </UploadedFileName>
          )}
          {currentFile && fileUpload?.failed && (
            <UploadError>
              <ErrorIcon />
              {errors.message || retryUpload}
              <button onClick={cancelFileUpload}>X</button>
            </UploadError>
          )}
        </SubmitButtonContainer>
      </div>
    );
  };

  const renderSessionsList = () => {
    if (noSessions && loadingSessions) return <Spinner loading={loadingSessions} />;
    if (noSessions)
      return (
        <NoSessions>
          <div>{noSessionsTitle}</div>
          <div>{noSessionsBody}</div>
        </NoSessions>
      );
    return (
      <SessionsList ref={sessionsListRef}>
        {Object.entries(groupedSessions).map(([section, sessions]) => (
          <SessionsSection>
            <SessionsSectionTitle>{section}</SessionsSectionTitle>
            {sessions.map((session: ISession) => (
              <Session
                key={session.sessionId}
                session={session}
                clickedSessionMenu={clickedSessionMenu}
                sessionToRename={sessionToRename}
                sessionsTitlesRef={sessionsTitlesRef}
                setCurrentSession={setCurrentSession}
                handleSessionMenuClick={handleSessionMenuClick}
                handleSessionMenuClickaway={handleSessionMenuClickaway}
                handleDeleteClick={handleDeleteClick}
                handleRenameClick={handleRenameClick}
                handleRenameBlur={handleRenameBlur}
                onRenameKeyDown={onRenameKeyDown}
                displaySessionMenuUp={displaySessionMenuUp}
              />
            ))}
          </SessionsSection>
        ))}
        {loadingSessions && (
          <InfiniteLoader>
            <Spinner loading={loadingSessions} inline size="20px" />
          </InfiniteLoader>
        )}
      </SessionsList>
    );
  };

  const renderSessions = () => {
    const hideTipsPops = isMedium && !mobileDrawerOpen;
    const { isHidden, Component } = renderTipsPopup();

    const renderAboutButton = () => {
      const component = (
        <AboutButton onClick={onClickAbout}>
          {isHidden && <InfoIcon />}
          {isHidden ? about : closeTips}
        </AboutButton>
      );
      if (!isMedium) return component;
      if (isMedium && isHidden) return component;
      if (!isHidden) return null;
      return component;
    };
    return (
      <SessionsContainer mobileDrawerOpen={mobileDrawerOpen}>
        <SessionsHeader>
          <div>
            <h1>{title}</h1>
            {/* <BetaTag>{beta}</BetaTag> */}
          </div>
          <PlusButton onClick={handleStartNewChat}>
            <PlusIcon />
          </PlusButton>
        </SessionsHeader>
        {renderSessionsList()}

        {!hideTipsPops && (
          <ButtonTipsPopupContainer isHidden={isHidden}>
            {Component}
            {/* <Tips isHidden={!tipsPopup} /> */}
            {/* <AboutButton onClick={onClickAbout}>
              {isHidden && <InfoIcon />}
              {isHidden ? about : closeTips}
            </AboutButton> */}
            {renderAboutButton()}
          </ButtonTipsPopupContainer>
        )}
      </SessionsContainer>
    );
  };

  useEffect(() => {
    if (!noSessions) return;
    if (firstButtonClick) {
      toggleTipsPopup();
    }
  }, [noSessions, firstButtonClick]);

  const renderTipsPopup = () => {
    const isHidden = () => {
      if (!noSessions) return !tipsPopup;
      if (!firstButtonClick) return false;
      return !tipsPopup;
    };

    if ((!firstButtonClick && !noSessions) || loadingSessions) return { isHidden: true, Component: null };
    const isHiddenComp = isHidden();

    return {
      isHidden: isHiddenComp,
      Component: (
        <Tips
          close={() => {
            setFirstButtonClick(true);
            toggleTipsPopup();
          }}
          isHidden={isHidden()}
        />
      ),
    };
  };

  const renderDeleteModal = () => {
    return (
      showDeleteModal && (
        <ModalOverlay zIndex={11}>
          <ClickAwayListener onClickAway={toggleDeleteModal}>
            <ModalContainer>
              <ModalTitle>{deleteSessionTitle}</ModalTitle>
              <DeleteModalBody>
                {willDelete}
                <span>"{sessionToDelete?.title}"</span>
                {cantUndone}
              </DeleteModalBody>
              <DeleteModalButtonsContainer>
                <Button variant="secondary" onClick={toggleDeleteModal}>
                  {cancel}
                </Button>
                <Button variant="primary" onClick={handleSessionDelete}>
                  {deleteButton}
                </Button>
              </DeleteModalButtonsContainer>
            </ModalContainer>
          </ClickAwayListener>
        </ModalOverlay>
      )
    );
  };

  const renderErrorModal = () => {
    return (
      showErrorModal && (
        <ModalOverlay zIndex={12}>
          <ModalContainer>
            <ModalTitle>{somethingWrong}</ModalTitle>
            <AboutModalBody>{trouble}</AboutModalBody>
            <Button variant="primary" onClick={refresh}>
              {refreshButton}
            </Button>
          </ModalContainer>
        </ModalOverlay>
      )
    );
  };

  const renderMobileHeader = () => {
    return (
      <MobileHeader>
        {mobileDrawerOpen ? (
          <CloseIcon
            onClick={() => {
              toggleMobileDrawer();
              setFirstButtonClick(false);
            }}
          />
        ) : (
          <FolderIcon onClick={toggleMobileDrawer} />
        )}
        <div>
          <h1>{title}</h1>
          {/* <BetaTag>{beta}</BetaTag> */}
        </div>
        <PlusButton onClick={handleStartNewChat}>
          <PlusIcon />
        </PlusButton>
      </MobileHeader>
    );
  };

  return (
    <PageContainer>
      {/* {renderAboutModal()} */}
      {renderDeleteModal()}
      {renderErrorModal()}
      {renderSessions()}
      {renderMobileHeader()}
      <ChatContainer>
        {renderChatBody()}
        {renderPromptInput()}
      </ChatContainer>
    </PageContainer>
  );
};

export default NurseGPT;
