import BaseMembership from './BaseMembership';
import HeaderFreeBanner from '../../Header/HeaderFreeBanner';
import HeaderTrialBanner from '../../Header/HeaderTrialBanner';
import HeaderSponsoredBanner from '../../Header/HeaderSponsoredBanner';
import EnhanceApplyBanner from '../../Header/EnhanceApplyBanner';
import { applyPath, nurseGPTPath } from '../../Router/paths';
import { EApplyQuizState, EUserRole } from '../../types';
import { en } from '../../../i18n';
import ApplyRoleBanner from '../../Header/ApplyRoleBanner';
import { EQuestionIDS } from '../../ClicksQuiz/types';
//import { EQuestionIDS } from '../../ClicksQuiz/types';

class HeaderBanner extends BaseMembership {
  static headerRandomInt = -1;

  private isNurseGPT() {
    return this.getLocation() === this.mapLocation(nurseGPTPath);
  }
  public free() {
    if (this.isNurseGPT()) return null;
    return <HeaderFreeBanner />;
  }

  public sponsored() {
    if (this.isNurseGPT()) return null;
    return (
      <HeaderFreeBanner>
        <HeaderSponsoredBanner />
      </HeaderFreeBanner>
    );
  }
  public essential() {
    return null;
  }
  public allAccess() {
    return this.essential();
  }
  public trialling() {
    if (this.isNurseGPT()) return null;
    return (
      <HeaderFreeBanner>
        <HeaderTrialBanner />
      </HeaderFreeBanner>
    );
  }

  public overrideAll() {
    const applyQuizState = this.getApplyQuizState();
    const inCompletedEnhanceQuiz = [EApplyQuizState.BASE_DONE, EApplyQuizState.ENHANCE_STARTED].includes(
      applyQuizState,
    );

    const applyBannerVisible = [EApplyQuizState.BASE_NOT_STARTED, EApplyQuizState.BASE_STARTED].includes(
      applyQuizState,
    );

    const isApplyPath = this.getLocation() === this.mapLocation(applyPath);
    if (isApplyPath) return null;
    if (applyBannerVisible) {
      const randomBanner = this.selectRandomApplyRoleBanner();
      if (randomBanner) {
        return (
          <HeaderFreeBanner>
            <ApplyRoleBanner
              text={randomBanner.text}
              button={randomBanner.button}
              textSuffix={randomBanner.textSuffix}
              id={randomBanner.id as EQuestionIDS}
            />
          </HeaderFreeBanner>
        );
      }
    }

    if (inCompletedEnhanceQuiz) {
      return (
        <HeaderFreeBanner>
          <EnhanceApplyBanner isStarted={this.getApplyQuizState() === EApplyQuizState.ENHANCE_STARTED} />
        </HeaderFreeBanner>
      );
    }
    return null;
  }
  public useStrictFreeMembers() {
    return true;
  }

  public static setRandIndex(index: number) {
    HeaderBanner.headerRandomInt = index;
  }

  public static resetRandIndex() {
    HeaderBanner.headerRandomInt = -1;
  }

  private selectRandomApplyRoleBanner() {
    const role = this.getRole();
    if (role === EUserRole.USER) return null;
    const dataSource = role === EUserRole.UNDERGRAD ? en.header.undergradBanners : en.header.gradBanners;
    if (HeaderBanner.headerRandomInt >= 0 && HeaderBanner.headerRandomInt < dataSource.length)
      return dataSource[HeaderBanner.headerRandomInt];

    return null;
  }
}

export default HeaderBanner;
