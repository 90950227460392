import { styled } from '@mui/material/styles';
import { colors } from '../../themes';
import { thinScrollbar, zoomInAnimation, disappearZoomAnimation, zoomOutAnimation } from '../sharedStyled';
import { shouldForwardProps } from '../utils';

interface IMessageIconProps {
  backgroundColor: string;
}

interface ISessionsContainer {
  mobileDrawerOpen: boolean;
}

interface ISessionButton {
  isClicked: boolean;
}

interface ISession {
  isMenuClicked: boolean;
  isRenaming: boolean;
  removed?: boolean;
}

interface ISessionMenuItem {
  warning?: boolean;
}

interface ISessionTitle {
  isRenaming: boolean;
}

interface IRoleDropdownItem {
  selected: boolean;
}

interface IRoleDropdown {
  isOpen: boolean;
}

interface IModalOverlay {
  zIndex: number;
}

interface ISessionMenu {
  displayUp: boolean;
}

interface IMessageText {
  showLoader?: boolean;
  loaderUrl?: string;
}

interface ICopyContainer {
  isLastMessage?: boolean;
}

interface IButtonsPopupContainer {
  isHidden?: boolean;
}

const PageContainer = styled('div')`
  background-color: ${colors.purple5};
  width: 100vw;
  height: calc(100vh - 64px);
  @supports (height: 100dvh) {
    height: calc(100dvh - 64px);
  }
  display: flex;
  ${props => props.theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`;

const ChatContainer = styled('div')`
  height: 100%;
  width: max(72%, 100vw - 400px);
  position: relative;
  ${props => props.theme.breakpoints.down('md')} {
    width: 100%;
    height: calc(100% - 48px);
  }
  div.MuiInputBase-root {
    position: absolute;
    bottom: 43px;
    ${props => props.theme.breakpoints.down('sm')} {
      bottom: 49px;
    }
  }
  textarea {
    width: calc(92% - 94px);
    // max-width: 896px;
    margin: auto;
    outline: 1px solid ${colors.purple10};
    border-radius: 8px;
    padding: 12px 46px;
    font-size: 16px;
    color: ${colors.black};
    background-color: ${colors.purpleWhite};
    // -webkit-user-select: text; /* Safari, Chrome (up to version 79), and other WebKit browsers */
    // -moz-user-select: text; /* Firefox */
    // -ms-user-select: text; /* Internet Explorer/Edge */
    // -o-user-select: text; /* Opera (up to version 12.1) */
    // user-select: text; /* Standard */
    ${props => props.theme.breakpoints.up('sm')} {
      overflow-y: auto;
      ${thinScrollbar};
    }
    ::placeholder {
      color: ${colors.grey400};
      opacity: 1;
    }
    :hover {
      outline: 1px solid ${colors.purple30};
    }
    :focus {
      outline: 2px solid ${colors.purple30};
    }
  }
`;

const SubmitButtonContainer = styled('div')`
  position: absolute;
  bottom: 75px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(92% - 64px);
  // max-width: 896px;
  margin: auto;
  padding: 0px 32px;
  div.MuiInputAdornment-root:nth-child(2) {
    position: absolute;
    right: 15px;
    margin-top: 3.5px;
    ${props => props.theme.breakpoints.down('sm')} {
      margin-top: -1px;
    }
    svg {
      color: ${colors.white};
      background-color: ${colors.purple30};
      border-radius: 8px;
      padding: 3px;
    }
  }
  div.MuiInputAdornment-root:nth-child(3) {
    position: absolute;
    left: 15px;
    margin-top: 4px;
    input[type='file'] {
      display: none;
    }
    ${props => props.theme.breakpoints.down('sm')} {
      margin-top: -1px;
    }
  }
  && button.Mui-disabled svg {
    background-color: ${colors.purple10};
    color: ${colors.purple30};
  }
`;

const MessagesWrapper = styled('div')`
  height: calc(100% - 99px);
  overflow: auto;
  ${thinScrollbar};
  overflow-x: hidden;
`;

const MessagesContainer = styled('div')`
  width: 85%;
  // max-width: 880px;
  margin: auto;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${props => props.theme.breakpoints.down('md')} {
    width: 80%;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    width: 87%;
  }
`;

const Message = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-bottom: 40px;
  position: relative;
  :hover {
    > div:nth-child(3) {
      svg {
        display: block;
      }
    }
  }
`;

const MessageAvatar = styled('div')`
  font-size: 1.125rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 15px;
`;

const MessageText = styled('div')`
  font-size: 1rem;
  margin-left: 51px;
  line-height: 24px;
  svg {
    transform: translateY(5px);
    margin-top: -5px;
  }
  > div {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    > *:first-child {
      margin-top: 0;
    }
    > *:last-child {
      margin-bottom: 0;
    }
  }
`;

const MessageIcon = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['backgroundColor']),
})<IMessageIconProps>(
  ({ backgroundColor, theme }) => `
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${backgroundColor};
  color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  font-size: 1.125rem;
  font-weight: 700;
  text-align: center;
  border: 2px solid white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  svg {
    width: 22px;
  }
  ${theme.breakpoints.down('sm')} {
    width: 32px;
    height: 32px;
    font-size: 0.875rem;
  }
`,
);

const UnstartedChat = styled('div')`
  width: 92%;
  // max-width: 960px;
  height: calc(100% - 155px);
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const UnstartedChatTitle = styled('h1')`
  font-size: 1.25rem;
  font-weight: 700;
  color: ${colors.purple40};
  margin: 0;
  text-align: center;
`;

const UnstartedChatExamplesContainer = styled('div')`
  width: 100%;
`;

const UnstartedChatExamples = styled('div')``;

const UnstartedChatExample = styled('div')`
  background-color: ${colors.purpleWhite};
  border-radius: 8px;
  padding: 20px 24px;
  width: calc(100% - 48px);
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.2s;
  :hover {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    transform: scale(1.01);
  }
  ${props => props.theme.breakpoints.down('sm')} {
    :nth-child(3),
    :nth-child(4) {
      display: none;
    }
  }
`;

const UnstartedChatExampleTitle = styled('div')`
  font-size: 1rem;
  width: 100%;
  font-weight: 700;
`;

const UnstartedChatIcon = styled('div')`
  svg {
    width: 64px;
    height: 73px;
  }
`;

const UnstartedChatHeader = styled('div')`
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
`;

const DisclaimerText = styled('div')`
  position: absolute;
  bottom: -58px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.875rem;
  color: ${colors.grey400};
  width: 100%;
  text-align: center;
  ${props => props.theme.breakpoints.down('sm')} {
    width: 60%;
    font-size: 0.68rem;
  }
`;

const MessageLoader = styled('div')`
  font-size: 1rem;
  font-style: italic;
`;

const SessionsContainer = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['mobileDrawerOpen']),
})<ISessionsContainer>(
  ({ mobileDrawerOpen, theme }) => `
  width: 28%;
  max-width: 400px;
  height: 100%;
  background-color: ${colors.darkBlue};
  position: relative;
  transition: all 0.3s;
  ${theme.breakpoints.down('md')} {
    position: fixed;
    z-index: 10;
    width: 100%;
    max-width: none;
    margin-top: 47px;
    ${
      mobileDrawerOpen
        ? `
    left: 0;
    `
        : `
    left: -100%;
    `
    }
  }
`,
);

const SessionsHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px;
  > div {
    display: flex;
    align-items: center;
    gap: 12px;
    h1 {
      font-size: 1.25rem;
      font-weight: 700;
      color: ${colors.white};
      margin: 0;
    }
  }
  ${props => props.theme.breakpoints.down('md')} {
    display: none;
  }
`;

const BetaTag = styled('div')`
  background-color: ${colors.blue50};
  padding: 1px 12px;
  border-radius: 24px;
  font-size: 0.75rem;
  font-weight: 700;
  color: ${colors.highlightBlue};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.theme.breakpoints.down('md')} {
    padding: 1px 6px;
    font-size: 0.7rem;
  }
`;

const PlusButton = styled('div')`
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background-color: ${colors.purple30};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${colors.purple20};
  }
  ${props => props.theme.breakpoints.down('md')} {
    width: 24px;
    height: 24px;
    border-radius: 8px;
  }
`;

const NoSessions = styled('div')`
  height: calc(100% - 82px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: ${colors.white};
  div:nth-child(1) {
    font-size: 1.125rem;
    font-weight: 700;
    text-align: center;
  }
  div:nth-child(2) {
    font-size: 1rem;
    width: 88%;
    text-align: center;
    line-height: 24px;
  }
  ${props => props.theme.breakpoints.down('md')} {
    height: calc(100% - 180px);
  }
`;

const ButtonTipsPopupContainer = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isHidden']),
})<IButtonsPopupContainer>(
  ({ isHidden, theme }) => `
  width: 92%;
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  ${theme.breakpoints.down('md')} {
    position: fixed;
    bottom: ${isHidden ? '15px' : '0px'};
  }
`,
);

const AboutButton = styled('div')`
  background-color: ${colors.darkPurple10};
  color: ${colors.purple10};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 0.875rem;
  font-weight: 700;
  padding: 16px 0;
  cursor: pointer;
  :hover {
    background-color: ${colors.darkPurple40};
  }
`;

const ModalOverlay = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['zIndex']),
})<IModalOverlay>(
  ({ zIndex }) => `
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: ${zIndex};
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(24, 24, 24, 0.4);
`,
);

const ModalContainer = styled('div')`
  width: 33%;
  max-width: 480px;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  background-color: ${colors.white};
  border-radius: 16px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
  ${props => props.theme.breakpoints.down('md')} {
    width: 50%;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    width: 80%;
  }
`;

const ModalTitle = styled('h3')`
  font-size: 1.125rem;
  font-weight: 700;
  text-align: center;
  margin: 0;
`;

const AboutModalBody = styled('div')`
  font-size: 1rem;
  line-height: 24px;
  text-align: center;
  font-weight: 700;
`;

const DeleteModalBody = styled('div')`
  font-size: 1rem;
  line-height: 24px;
  text-align: center;
  span {
    font-weight: 700;
  }
`;

const SessionsList = styled('div')`
  width: calc(100% - 46px);
  height: calc(100% - 172px);
  display: flex;
  flex-direction: column;
  gap: 35px;
  overflow: auto;
  padding: 0 23px;
  margin-top: 20px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  ${props => props.theme.breakpoints.down('md')} {
    height: calc(100% - 200px);
  }
`;

const SessionItem = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isMenuClicked', 'isRenaming']),
})<ISession>(
  ({ isMenuClicked, isRenaming, removed }) => `
  position: relative;
  width: calc(100% - 30px);
  background-color: ${colors.darkPurple20};
  border-radius: 8px;
  font-size: 0.95rem;
  padding: 13px 15px;
  color: ${colors.white};
  flex-shrink: 0;
  cursor: pointer;
  transition: background-color 0.2s;
  ${
    isMenuClicked
      ? `
  background-color: ${colors.darkPurple30};
  > div {
    display: flex;
  }
  `
      : ''
  }
  :hover {
    background-color: ${colors.darkPurple30};
    > div {
      display: flex;
    }
  }
  ${
    isRenaming
      ? `outline: 2px solid ${colors.highlightBlue};
         background-color: ${colors.darkPurple30};`
      : ''
  }
  ${
    removed
      ? `animation: heightOut 0.4s ease;
         animation-fill-mode: forwards;`
      : ''
  }
  @keyframes heightOut {
    0% {
      overflow: hidden;
      div {
        visibility: hidden;
      }
    }
    50% {
      overflow: hidden;
      div {
        visibility: hidden;
      }
      height: 0;
      padding: 0 15px;
      opacity: 0;
      margin-bottom: -8px;
    }
    100% {
      overflow: hidden;
      div {
        visibility: hidden;
      }
      height: 0;
      padding: 0 15px;
      opacity: 0;
      margin-bottom: -8px;
      display: none;
    };
  }
`,
);

const SessionsSection = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SessionsSectionTitle = styled('div')`
  font-size: 0.875rem;
  color: ${colors.purple5};
`;

const MobileHeader = styled('div')`
  background-color: ${colors.darkBlue};
  display: none;
  color: ${colors.white};
  padding: 12px 20px;
  h1 {
    margin: 0;
    font-size: 1rem;
  }
  > div:nth-child(2) {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  ${props => props.theme.breakpoints.down('md')} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const SessionButton = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isClicked']),
})<ISessionButton>(
  ({ isClicked }) => `
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  display: none;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  z-index: 2;
  transition: all 0.2s;
  ${isClicked ? `background-color: ${colors.darkPurple50};` : ''}
  :hover {
    background-color: ${colors.darkPurple50};
  }
`,
);

const SessionGradient = styled('div')`
  background: linear-gradient(90deg, rgba(47, 43, 56, 0) 0%, rgba(67, 61, 83, 1) 60%);
  width: 25%;
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  transition: all 0.2s;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const SessionMenu = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['warning']),
})<ISessionMenu>(
  ({ displayUp }) => `
  position: absolute;
  z-index: 1;
  width: 63%;
  right: 0;
  background-color: ${colors.darkPurple30};
  padding: 8px;
  border-radius: 8px;
  transition: all 0.2s;
  display: flex;
  flex-direction: column;
  gap: 3px;
  ${displayUp ? 'top: -205%;' : 'bottom: -205%;'}
`,
);

const SessionMenuItem = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['warning']),
})<ISessionMenuItem>(
  ({ warning }) => `
  display: flex;
  align-items: center;
  gap: 18px;
  border-radius: 4px;
  width: calc(100% - 16px);
  padding: 8px;
  transition: all 0.2s;
  font-size: 0.875rem;
  ${warning ? `color: ${colors.red3};` : ''}
  :hover {
    background-color: ${colors.darkPurple60};
  }
  svg {
    width: 16px;
    height: 16px;
  }
  `,
);

const RoleContainer = styled('div')`
  position: absolute;
  display: flex;
  align-items: center;
  gap: 30px;
  left: 0;
  right: 0;
  bottom: 112px;
  width: 92%;
  margin: auto;
  > div:nth-child(1) {
    font-size: 0.875rem;
    font-weight: 700;
  }
  .MuiFormGroup-root {
    flex-direction: row;
    gap: 8px;
    label {
      background-color: ${colors.white};
      padding: 8px 18px;
      border: 1px solid ${colors.grey600};
      border-radius: 4px;
      margin: 0;
      gap: 8px;
      span {
        padding: 0;
        font-size: 0.875rem;
        svg {
          width: 16px;
          height: 16px;
        }
        svg:nth-child(1) {
          color: ${colors.grey600};
        }
      }
    }
  }
  ${props => props.theme.breakpoints.down('sm')} {
    display: none;
  }
`;

const MobileRoleContainer = styled('div')`
  display: none;
  position: absolute;
  width: 100%;
  bottom: 112px;
  ${props => props.theme.breakpoints.down('sm')} {
    display: block;
  }
`;

const SelectedRole = styled('div')`
  width: calc(92% - 48.5px);
  margin: auto;
  font-size: 0.9rem;
  font-weight: 700;
  background-color: ${colors.purple10};
  color: ${colors.purple40};
  border-radius: 4px;
  padding: 8px 24px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  svg {
    display: flex;
    align-items: center;
    margin-top: 4px;
  }
`;

const RoleDropdown = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['mobileDrawerOpen']),
})<IRoleDropdown>(
  ({ isOpen }) => `
  position: absolute;
  width: calc(92% - 18px);
  padding: 8px;
  margin: auto;
  left: 0;
  right: 0;
  top: -78px;
  border: 1px solid ${colors.purple20};
  border-radius: 8px;
  background-color: ${colors.white};
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  transition: visibility 0.1s, opacity 0.1s ease-in-out;
  ${
    isOpen
      ? `
    visibility: visible;
    opacity: 1;
  `
      : `
    visibility: hidden;
    opacity: 0;
  `
  }
`,
);

const SessionTitle = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isRenaming']),
})<ISessionTitle>(
  ({ isRenaming }) => `
  overflow: hidden;
  text-overflow: ${isRenaming ? 'unset' : 'ellipsis'};
  cursor: ${isRenaming ? 'text' : 'pointer'};
  caret-color: ${colors.highlightBlue};
  text-wrap: nowrap;
  white-space: nowrap;
  outline: none;
`,
);

const DeleteModalButtonsContainer = styled('div')`
  display: flex;
  gap: 24px;
  button:nth-child(2) {
    background-color: ${colors.red300};
    border: none;
  }
`;

const RoleDropdownItem = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['mobileDrawerOpen']),
})<IRoleDropdownItem>(
  ({ selected }) => `
  font-size: 1rem;
  font-weight: 700;
  padding: 8px;
  border-radius: 4px;
  ${selected ? `background-color: ${colors.purple300};` : ''}
  :hover {
    background-color: ${colors.purple300};
  }
`,
);

const InfiniteLoader = styled('div')`
  position: relative;
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -20px;
  margin-bottom: 10px;
`;

const UploadedFileName = styled('div')`
  position: absolute;
  left: 0;
  top: -65px;
  border-radius: 6px;
  padding: 10px 12px;
  background-color: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  span:nth-child(2) {
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }
  > svg {
    margin-bottom: 3px;
  }
  button {
    display: none;
  }
  &:hover {
    button {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%, -50%);
      width: 15px;
      height: 15px;
      border: 1px solid #9a9b9c;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1.9;
      font-size: 11px;
      font-weight: bold;
      background-color: #ebebeb;
      cursor: pointer;
      text-align: center;
      color: #6e6e6e;
    }
  }
`;

const UploadMessage = styled('div')`
  margin-left: 51px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 33%;
  border: 1px solid ${colors.purple10};
  padding: 8px;
  border-radius: 12px;
  gap: 8px;
  font-size: 0.875rem;
  overflow: hidden;
  > div:nth-child(2) {
    width: calc(100% - 56px);
    div:nth-child(1) {
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    div:nth-child(2) {
      color: ${colors.grey};
    }
  }
  > div:nth-child(1) {
    width: 40px;
    height: 40px;
    background-color: ${colors.purple10};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    svg {
      width: 25px;
      height: 25px;
    }
  }
  ${props => props.theme.breakpoints.down('sm')} {
    width: 50%;
  }
`;
const CopyContainer = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isLastMessage']),
})<ICopyContainer>(
  ({ isLastMessage }) => `
  svg {
    display: ${isLastMessage ? 'block' : 'none'};
  }
  span {
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    :hover {
      background-color: ${colors.purple10};
    }
  }
  margin-left: 45px;
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  height: 30px;
  width: 100%;
  margin-top: 15px;
`,
);

const UploadError = styled('div')`
  position: absolute;
  left: 0;
  top: 0;
  transform: translateY(calc(-100% - 27px));
  border-radius: 6px;
  padding: 10px 12px;
  display: flex;
  gap: 6px;
  align-items: flex-start;
  font-size: 1rem;
  background-color: ${colors.pink};
  color: ${colors.black};
  svg {
    width: 18.33px;
    height: 18.33px;
  }
  button {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    width: 15px;
    height: 15px;
    border: 1px solid #9a9b9c;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.9;
    font-size: 11px;
    font-weight: bold;
    background-color: #ebebeb;
    cursor: pointer;
    text-align: center;
    color: #6e6e6e;
  }
`;

export {
  PageContainer,
  ChatContainer,
  MessagesContainer,
  Message,
  MessageAvatar,
  MessageText,
  MessagesWrapper,
  MessageIcon,
  UnstartedChat,
  UnstartedChatTitle,
  UnstartedChatExamplesContainer,
  UnstartedChatExamples,
  UnstartedChatExample,
  UnstartedChatIcon,
  UnstartedChatHeader,
  UnstartedChatExampleTitle,
  SubmitButtonContainer,
  DisclaimerText,
  MessageLoader,
  SessionsContainer,
  BetaTag,
  PlusButton,
  SessionsHeader,
  NoSessions,
  AboutButton,
  ModalOverlay,
  ModalContainer,
  ModalTitle,
  AboutModalBody,
  SessionsList,
  SessionItem,
  SessionsSection,
  SessionsSectionTitle,
  MobileHeader,
  SessionButton,
  SessionGradient,
  SessionMenu,
  SessionMenuItem,
  SessionTitle,
  DeleteModalBody,
  DeleteModalButtonsContainer,
  RoleContainer,
  MobileRoleContainer,
  SelectedRole,
  RoleDropdown,
  RoleDropdownItem,
  InfiniteLoader,
  UploadedFileName,
  UploadMessage,
  CopyContainer,
  UploadError,
  ButtonTipsPopupContainer,
};
